import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Homepage from './Pages/Homepage';
import Prodpage from './Pages/Prodpage';
import Productinfopage from './Pages/Productinfopage';
import Aboutpage from './Pages/Aboutpage';
import Contactpage from './Pages/Contactpage';
import NotFoundPage from './Pages/NotFound';
import ScrollToTop from './Compontents/ScrolltoTop';

function App() {
  return (
    <>
        <Router>
          <ScrollToTop/>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/products-page" element={<Prodpage />} />
            <Route path="/product-info-page/:productId" element={<Productinfopage/>} />
            <Route path="/about-page" element={<Aboutpage />} />
            <Route path="/contact-page" element={<Contactpage />} />
            <Route path="*" element={<NotFoundPage/>} />
          </Routes>
        </Router>
    </>
  );
}

export default App;
