import React, { useEffect, useState } from "react";
import { Click, Cooker, Featuredproduct, Power, Thunder, Warm } from "../assets";
import "../Compontents/ProductFeatures.css"
const ProductFeatures = () => {
  const descriptions = [
    { title: "Cook Easy, Stay Healthy", description: "Our Promise with Black & Fighter!" },
    { title: "Durable, Reliable, and Easy", description: "Black & Fighter Brings Excellence to Every Home!" },
    { title: "Brightening Your Home with Smart Technology", description: "Black & Fighter Promises Excellence!" },
    { title: "Quality You Can Trust, Prices You'll Love", description: "Black & Fighter Delivers!" },  
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Change description every 3 seconds
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % descriptions.length);
    }, 5000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="product-feature-main">
      <div className="prod-main-1">
      <div className="product-features">
      <div className="first-part">
        <div className="top-part">
          <div className="features yellow">
            <img src={Thunder} alt="Voltage" />
            <p>220-240V</p>
          </div>
          <div className="features white-bg">
            <img src={Cooker} alt="Removable Non Stick" />
            <p>Removable Non Stick</p>
          </div>
        </div>
        <div className="bottom-part yellow warranty">
          <div className="features">
            <p>2 YEARS</p>
            <span>WARRANTY</span>
          </div>
        </div>
      </div>
      <div className="second-part">
        <div className="features main-product">
          <img src={Featuredproduct} alt="" />
        </div>
      </div>
      <div className="third-part">
        <div className="top-part">
          <div className="features yellow">
            <img src={Warm} alt="Keep Warm Functions" />
            <p>Keep Warm Functions</p>
          </div>
          <div className="features white-bg">
            <img src={Power} alt="Product" />
            <p>700 W</p>
          </div>
        </div>
        <div className="bottom-part yellow">
          <div className="features">
            <img src={Click} alt="Cool Touch" />
            <p>Cool Touch</p>
          </div>
        </div>
      </div>
    </div>
      </div>
    <div className="product-descriptions">
        <h2>{descriptions[currentIndex].title}</h2>
        <p>{descriptions[currentIndex].description}</p>
      </div>
    </div>
  );
};

export default ProductFeatures;
