import React, { useState } from 'react';
import "./Products.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import { ProductBanner1, ProductBanner2, ProductBanner3, ProductBanner4, ProductBanner5 } from "../assets/index.js"; // Import other product images as needed
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Autoplay} from 'swiper/modules';

const Products = () => {
  const [showMore, setShowMore] = useState({});

  const products = [
    {
      id: 1,
      image: ProductBanner2,
      title: "Steam Iron",
      features: [
        "Nano Ceramic Soleplate-2200W",
        "Self-Cleaning",
        "Micro Spray",
        "Anti Calc & Anti Drip",
        "Over Heat Protection",
        "Powerful Burst Steam",
        "Variable Steam Control",
      ]
    },
    {
      id: 2,
      image: ProductBanner1,
      title: "Air Fryer",
      features: [
       "Adjustable Temperature",
      "Over-Heat Protection",
      "Cool-Touch Handle",
      "Automatic Shut Off"
      ]
    },
    {
      id: 3,
      image: ProductBanner3,
      title: "Hair Trimmer",
      features: [
       "Powerful Motor",
      "Stainless Steel Blades",
      "Compact & Lightweight for ease of use and handling",
      ]
    },
    {
      id: 4,
      image: ProductBanner5,
      title: "Gas Stove",
      features: [
       "Double Burner",
        "410 Stainless Steel",
        "Low Gas Consumption",
        "Electroplated Pan",
        "Support & Easy Clean",
      ]
    },
    {
      id: 5,
      image: ProductBanner4,
      title: "LED Flash Light",
      features: [
       "Aircraft Aluminium Alloy Body",
      "Super Bright",
      "Water Resistant",
      "Shock Resistance"
      ]
    },

  ];

  const handleToggle = (id) => {
    setShowMore((prevShowMore) => ({
      ...prevShowMore,
      [id]: !prevShowMore[id],
    }));
  };

  return (
    <div className='product'>
      <div className="heading">PRODUCTS</div>
      <hr />
      <Swiper
        slidesPerView={3}
        spaceBetween={20}
        loop={true}
        pagination={{clickable: true }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        {products.map((product) => (
          <SwiperSlide key={product.id}>
            <div className="slide-top">
              <img className='air' src={product.image} alt={product.title} />
            </div>
            <div className={`slide-bottom ${showMore[product.id] ? "expanded" : ""}`}>
              <div className="description">
                <h2>{product.title}</h2>
                <div className="desc">
                  <ul>
                    {product.features.slice(0, 2).map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                    {showMore[product.id] && product.features.slice(2).map((feature, index) => (
                      <li key={index + 2}>{feature}</li>
                    ))}
                  </ul>
                  <div className="toggle-arrow" onClick={() => handleToggle(product.id)}>
                    {showMore[product.id] ? '▲' : '▼'}
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Products;
