import React, { useEffect, useState } from "react";
import "../Compontents/Offer.css";
import { Offer1, Offer2, Offer3, Offer4, Offer5 } from "../assets";

const Offers = () => {
  const OfferImage = [Offer1,Offer2,Offer3,Offer4,Offer5]
  const [currentOfferIndex, setCurrentOfferIndex] = useState(0);
  useEffect(() => {
    // Set up an interval to change the offer image every 5 seconds
    const interval = setInterval(() => {
      setCurrentOfferIndex((prevIndex) => (prevIndex + 1) % OfferImage.length); // Loop back to the first image when reaching the last one
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="nothin">
      <div className="offer-section">
      <div className="offer-image">
      <img src={OfferImage[currentOfferIndex]} alt="Offer" />
    </div>
        <div className="rightbox">
        <div className="c1"></div>
        <div className="c2"></div>
        </div>
        <div className="txt">
        <h2>Easy Cooking, Healthy Living, Perfectly Done!</h2>
        <p>Our kitchen appliances combine smart technology and sleek design to make cooking easier, faster, and more enjoyable</p>
        </div>
      </div>
      <div className="cont"></div>
    </div>
  );
};

export default Offers;